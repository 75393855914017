// Generated by Framer (c563d2c)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["Ksk5hA8UB", "nFffE4JBy", "jHgiufPoF", "IMQUob7Vw", "CWheV7Uwo", "fHwi1coIE", "ZAUW9QfjE", "U_dg6SpNC", "Uv1ObUzLA", "bO9NyjHSo", "Cua4j60zu", "SX3iOEcMk", "UNwStLkMk", "LrQfHIJAY"];

const serializationHash = "framer-j6Hk9"

const variantClassNames = {bO9NyjHSo: "framer-v-q4pblr", Cua4j60zu: "framer-v-1j21nps", CWheV7Uwo: "framer-v-y2vfmk", fHwi1coIE: "framer-v-dqcqjl", IMQUob7Vw: "framer-v-1ake8ve", jHgiufPoF: "framer-v-17d8ars", Ksk5hA8UB: "framer-v-tcxa6l", LrQfHIJAY: "framer-v-1nhwf4z", nFffE4JBy: "framer-v-1lx8kgo", SX3iOEcMk: "framer-v-1x3otsp", U_dg6SpNC: "framer-v-bt3e80", UNwStLkMk: "framer-v-1qzacfa", Uv1ObUzLA: "framer-v-162ms7i", ZAUW9QfjE: "framer-v-1fiydhj"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {"$spacing-10": "Ksk5hA8UB", "$spacing-100": "jHgiufPoF", "$spacing-15": "UNwStLkMk", "$spacing-20": "SX3iOEcMk", "$spacing-200": "nFffE4JBy", "$spacing-25": "Cua4j60zu", "$spacing-30": "bO9NyjHSo", "$spacing-40": "Uv1ObUzLA", "$spacing-5": "LrQfHIJAY", "$spacing-50": "U_dg6SpNC", "$spacing-60": "ZAUW9QfjE", "$spacing-70": "fHwi1coIE", "$spacing-80": "CWheV7Uwo", "$spacing-90": "IMQUob7Vw"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "Ksk5hA8UB"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "Ksk5hA8UB", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const sharedStyleClassNames = []

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(scopingClassNames, "framer-tcxa6l", className, classNames)} data-framer-name={"$spacing-10"} layoutDependency={layoutDependency} layoutId={"Ksk5hA8UB"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({bO9NyjHSo: {"data-framer-name": "$spacing-30"}, Cua4j60zu: {"data-framer-name": "$spacing-25"}, CWheV7Uwo: {"data-framer-name": "$spacing-80"}, fHwi1coIE: {"data-framer-name": "$spacing-70"}, IMQUob7Vw: {"data-framer-name": "$spacing-90"}, jHgiufPoF: {"data-framer-name": "$spacing-100"}, LrQfHIJAY: {"data-framer-name": "$spacing-5"}, nFffE4JBy: {"data-framer-name": "$spacing-200"}, SX3iOEcMk: {"data-framer-name": "$spacing-20"}, U_dg6SpNC: {"data-framer-name": "$spacing-50"}, UNwStLkMk: {"data-framer-name": "$spacing-15"}, Uv1ObUzLA: {"data-framer-name": "$spacing-40"}, ZAUW9QfjE: {"data-framer-name": "$spacing-60"}}, baseVariant, gestureVariant)}/></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-j6Hk9.framer-1qz3m7q, .framer-j6Hk9 .framer-1qz3m7q { display: block; }", ".framer-j6Hk9.framer-tcxa6l { align-content: flex-start; align-items: flex-start; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 0px; height: 10px; justify-content: flex-start; overflow: hidden; padding: 0px; position: relative; width: 10px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-j6Hk9.framer-tcxa6l { gap: 0px; } .framer-j6Hk9.framer-tcxa6l > * { margin: 0px; margin-bottom: calc(0px / 2); margin-top: calc(0px / 2); } .framer-j6Hk9.framer-tcxa6l > :first-child { margin-top: 0px; } .framer-j6Hk9.framer-tcxa6l > :last-child { margin-bottom: 0px; } }", ".framer-j6Hk9.framer-v-1lx8kgo.framer-tcxa6l { height: 200px; width: 200px; }", ".framer-j6Hk9.framer-v-17d8ars.framer-tcxa6l { height: 100px; width: 100px; }", ".framer-j6Hk9.framer-v-1ake8ve.framer-tcxa6l { height: 90px; width: 90px; }", ".framer-j6Hk9.framer-v-y2vfmk.framer-tcxa6l { height: 80px; width: 80px; }", ".framer-j6Hk9.framer-v-dqcqjl.framer-tcxa6l { height: 70px; width: 70px; }", ".framer-j6Hk9.framer-v-1fiydhj.framer-tcxa6l { height: 60px; width: 60px; }", ".framer-j6Hk9.framer-v-bt3e80.framer-tcxa6l { height: 50px; width: 50px; }", ".framer-j6Hk9.framer-v-162ms7i.framer-tcxa6l { height: 40px; width: 40px; }", ".framer-j6Hk9.framer-v-q4pblr.framer-tcxa6l { height: 30px; width: 30px; }", ".framer-j6Hk9.framer-v-1j21nps.framer-tcxa6l { height: 25px; width: 25px; }", ".framer-j6Hk9.framer-v-1x3otsp.framer-tcxa6l { height: 20px; width: 20px; }", ".framer-j6Hk9.framer-v-1qzacfa.framer-tcxa6l { height: 15px; width: 15px; }", ".framer-j6Hk9.framer-v-1nhwf4z.framer-tcxa6l { height: 5px; width: 5px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 10
 * @framerIntrinsicWidth 10
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"nFffE4JBy":{"layout":["fixed","fixed"]},"jHgiufPoF":{"layout":["fixed","fixed"]},"IMQUob7Vw":{"layout":["fixed","fixed"]},"CWheV7Uwo":{"layout":["fixed","fixed"]},"fHwi1coIE":{"layout":["fixed","fixed"]},"ZAUW9QfjE":{"layout":["fixed","fixed"]},"U_dg6SpNC":{"layout":["fixed","fixed"]},"Uv1ObUzLA":{"layout":["fixed","fixed"]},"bO9NyjHSo":{"layout":["fixed","fixed"]},"Cua4j60zu":{"layout":["fixed","fixed"]},"SX3iOEcMk":{"layout":["fixed","fixed"]},"UNwStLkMk":{"layout":["fixed","fixed"]},"LrQfHIJAY":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerBhU5aUXL1: React.ComponentType<Props> = withCSS(Component, css, "framer-j6Hk9") as typeof Component;
export default FramerBhU5aUXL1;

FramerBhU5aUXL1.displayName = "Spacing 2";

FramerBhU5aUXL1.defaultProps = {height: 10, width: 10};

addPropertyControls(FramerBhU5aUXL1, {variant: {options: ["Ksk5hA8UB", "nFffE4JBy", "jHgiufPoF", "IMQUob7Vw", "CWheV7Uwo", "fHwi1coIE", "ZAUW9QfjE", "U_dg6SpNC", "Uv1ObUzLA", "bO9NyjHSo", "Cua4j60zu", "SX3iOEcMk", "UNwStLkMk", "LrQfHIJAY"], optionTitles: ["$spacing-10", "$spacing-200", "$spacing-100", "$spacing-90", "$spacing-80", "$spacing-70", "$spacing-60", "$spacing-50", "$spacing-40", "$spacing-30", "$spacing-25", "$spacing-20", "$spacing-15", "$spacing-5"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerBhU5aUXL1, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})